import Container from 'react-bootstrap/Container';
// import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
// import { useNavigate } from 'react-router-dom';


import React, {useState, useEffect} from 'react'
import axios from 'axios';
import { Placeholder } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';

const Banner = () => {
  const [banners, setBanners] = useState([]);
  const {REACT_APP_API_URL} = process.env;
  axios.defaults.baseURL = REACT_APP_API_URL;

  useEffect(() => {
    axios
      .get("bannerstype/web", { headers: {
        "content-type": "application/json"
      }})
      .then((result) => {
        const newPost = result.data.map((banners) => {
          return {
            ...banners,
            active: true
          }
        })  

        setBanners(newPost);
      })
      .catch((error) => console.log(error));
  }, []);

  const listBanners = banners.map((data) => {
    return (
      
      data.active && (
        <Carousel.Item>
          <img
            className="banner_img"
            src={REACT_APP_API_URL + 'uploads/banners/' + data.banner_img_link}
            alt="First slide"
          />
          <Carousel.Caption>
            {/* <h1 className='text-white banner-h1'>Find Your Best Spot With Us</h1>
            <h3 className='text-white banner-h3'>Allows you to catch the venues near your area and get you the right direction, so you won't get lost!</h3>
            <Button className='btn-nb-primary px-5 my-2 ' onClick={goNearby}>Nearby</Button> */}
          </Carousel.Caption>
        </Carousel.Item>
      )

    );
});

const listPlaceholder = [
  <Carousel.Item> 
    <Card bg='dark'
              key='dark'
              text='white'>
        <Placeholder animation='glow'>
          <Placeholder xs={12} style={{height: '250px'}}/>
        </Placeholder>
    </Card>
  </Carousel.Item>,
  
]


  return (
    <>
    <Container fluid>
        
    <Carousel data-bs-theme="dark" controls={true}  indicators={false}>
      {listBanners.length > 0 ?
            
            listBanners : listPlaceholder

         }
    </Carousel>
    </Container>
    </>
  )
}

export default Banner