import React, {useState, useEffect} from 'react'
import { Col, Container, Row, Card, Button} from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import { slice } from 'lodash';
import parse from 'html-react-parser';

import { Helmet } from 'react-helmet';

const BlogDetail = () => {
    const location = useLocation();
    const data = location.state
    const {REACT_APP_API_URL} = process.env
    axios.defaults.baseURL = REACT_APP_API_URL;
    const navigate = useNavigate();

    const [blogs, setBlogs] = useState([]);
    const initialBlogs = slice(blogs, 0, 3)

    useEffect(() => { 
      axios
        .get("pubblogs", { headers: {
          "content-type": "application/json"
        }})
        .then((result) => {
          const newPost = result.data.map((blogs) => {
            return {
              ...blogs,
              active: true
            }
          })  

          newPost.sort((a,b) => (a.blog_ID > b.blog_ID) ? 1 : -1);
          setBlogs(newPost);
        })
        .catch((error) => console.log(error));
    }, []);

    const blogDetail = (data) => (e) => {
      data.blog_permalink !== null ? navigate('/blog/'+ data.blog_permalink, {state: data}) : navigate('/blogDetail', {state: data});
      window.scrollTo(0, 0)
    }  

  const listBlogs = initialBlogs.map((data, index) => {
      return (
        
        data.active && (
          <Col md="4" xs="6" key={index}>
              <Card bg='dark'
                    key='dark'
                    text='white' className='blog-card m-1'>
                  <Card.Img className="p-1"src={REACT_APP_API_URL + 'uploads/blog/' + data.blog_img_link} alt={data.blog_ID} />
                  <Card.Body>
                      <Card.Subtitle style={{fontSize: "12px"}}>{data.blog_date}</Card.Subtitle>
                      <Card.Title style={{fontSize: "16px"}}>{data.blog_title.length > 65 ? data.blog_title.substring(0,65) + '...' : data.blog_title}</Card.Title>
                      <div className='text-center d-grid gap-2'>
                      <Button className='btn-blog' onClick={blogDetail(data)}>Baca Selengkapnya</Button>
                      </div>
                  </Card.Body>
              </Card>    
          </Col>
          
        )

      );
  });

  return (
    <Container>
         <Helmet>
            <meta charSet="utf-8" />
            <title>{data.blog_title}</title>
            <meta name="keywords" content={data.blog_meta_key} />
            <meta name="description" content={data.blog_meta_desc} />
            <link rel="canonical" href={window.location.href } />
            <meta name="robots" content="INDEX, FOLLOW"></meta>
        </Helmet>
        <Row className='my-3'>
          <Col>
              <Card bg='dark'
                    key='dark'
                    text='white'>
                    <Row>
                        <Card.Header>
                          <Card.Subtitle className='mt-3 text-white' style={{fontSize: '30px'}}>{data.blog_title}</Card.Subtitle>
                          <Card.Subtitle className='my-2' style={{fontSize: '14px'}}><p  style={{display: 'inline', color: 'gray'}}>Editor by Admin | </p>{data.blog_date}</Card.Subtitle>
                        </Card.Header>
                        <Card.Body>
                          <Card.Img className="img-fluid blog_img" src={REACT_APP_API_URL + 'uploads/blog/' + data.blog_img_link} alt={data.venue_event_ID} />
                          <Card.Text className='my-2'>
                            {parse(data.blog_desc)}
                          </Card.Text>
                        </Card.Body>        
                      
                    </Row>  
              </Card>
          </Col>
        </Row>
        <Row className='my-3 text-center justify-content-md-center'>
            <h1>Blog Lainnya</h1>
        </Row>
        <Row className='mb-5'>
          {listBlogs}
        </Row>
    </Container>
  )
}

export default BlogDetail