import React, {useEffect, useState, useRef} from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Placeholder } from 'react-bootstrap';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import disco_icon from '../assets/Nightclub.svg'
import bar_icon from '../assets/Bar.svg'
import spa_icon from '../assets/Spa.svg'
import karaoke_icon from '../assets/Karaoke.svg'
import hotel_icon from '../assets/Hotel.svg'
import venue_icon from '../assets/where_to_vote.svg'
import clock_icon from '../assets/time-clock-circle.svg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGreaterThan, faLessThan } from '@fortawesome/free-solid-svg-icons'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import { slice } from 'lodash';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


// import required modules
import { Scrollbar } from 'swiper/modules';
import { Container } from 'react-bootstrap';

const Venue = () => {
    const [venues, setVenues] = useState([]);
    const {REACT_APP_API_URL} = process.env;
    axios.defaults.baseURL = REACT_APP_API_URL;
    const swiperRef = useRef();
    
    const navigate = useNavigate();
    const [searchFilter, setSearchFilter] = useState('bar');

    useEffect(() => {
        axios
          .get("pubvenue", { headers: {
            "content-type": "application/json"
          }})
          .then((result) => {
            const newPost = result.data.map((venues) => {
              return {
                ...venues,
                active: true
              }
            })  
            setVenues(newPost);
          })
          .catch((error) => console.log(error));
      }, [searchFilter]);

      const handleFilter = (type) => () => {
        setSearchFilter(type);
      };

      const filteredData = venues.filter(
        (item) =>
          item.type.toLowerCase().includes(searchFilter.toLowerCase())
      );

      const initialVenues = slice(filteredData, 0, 8);

      const venueDetail = (data) => (e) => {
        
        data.permalink !== null ? navigate('/'+ data.permalink, {state: data}) : navigate('/venueDetail', {state: data});
        
      }       

    const listVenues = initialVenues.map((data) => {
        return (
          
          data.active && (
            <SwiperSlide key={'venue-'+data.id}>
            <Card bg='dark'
                  key='dark'
                  text='white' onClick={venueDetail(data)} style={{cursor: 'pointer'}}>
                <Card.Img src={REACT_APP_API_URL + 'uploads/venue/' + data.main_image} alt={data.id} />
                <Card.Body className='px-0'>
                    <Card.Subtitle><img src={venue_icon} className='me-1' alt='venue_icon'/>{data.domisili}</Card.Subtitle>
                    <Card.Title className='my-0'>{data.name.length > 15 ? data.name.substring(0,15) + '...' : data.name}</Card.Title>
                    <Card.Text>
                    <img src={clock_icon} className='mt-1 me-1' alt='clock_icon'/>{data.opening_hour}
                    </Card.Text>
                </Card.Body>
            </Card>    
        </SwiperSlide>
          )

        );
    });

    const listPlaceholder = [
        <SwiperSlide key={'ph-venue-'+1}>  
          <Card bg='dark'
                    key='dark'
                    text='white'>
              <Placeholder animation='glow'>
                <Placeholder xs={12} style={{height: '180px'}}/>
              </Placeholder>        
            <Card.Body className='px-0'>
              <Placeholder as={Card.Subtitle} animation="glow">
                <Placeholder xs={6} />
              </Placeholder>
              <Placeholder as={Card.Title} animation="glow" className='my-0'>
                <Placeholder xs={8} />
              </Placeholder>
              <Placeholder as={Card.Text} animation="glow">
                <Placeholder xs={4} />
              </Placeholder>
            </Card.Body>
          </Card>
        </SwiperSlide>,
        <SwiperSlide key={'ph-venue-'+2}>  
        <Card bg='dark'
                  key='dark'
                  text='white'>
            <Placeholder animation='glow'>
              <Placeholder xs={12} style={{height: '180px'}}/>
            </Placeholder>        
          <Card.Body className='px-0'>
            <Placeholder as={Card.Subtitle} animation="glow">
              <Placeholder xs={6} />
            </Placeholder>
            <Placeholder as={Card.Title} animation="glow" className='my-0'>
              <Placeholder xs={8} />
            </Placeholder>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={4} />
            </Placeholder>
          </Card.Body>
        </Card>
      </SwiperSlide>,
      <SwiperSlide key={'ph-venue-'+3}>  
      <Card bg='dark'
                key='dark'
                text='white'>
          <Placeholder animation='glow'>
            <Placeholder xs={12} style={{height: '180px'}}/>
          </Placeholder>        
        <Card.Body className='px-0'>
          <Placeholder as={Card.Subtitle} animation="glow">
            <Placeholder xs={6} />
          </Placeholder>
          <Placeholder as={Card.Title} animation="glow" className='my-0'>
            <Placeholder xs={8} />
          </Placeholder>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={4} />
          </Placeholder>
        </Card.Body>
      </Card>
    </SwiperSlide>,
    <SwiperSlide key={'ph-venue-'+4}>  
    <Card bg='dark'
              key='dark'
              text='white'>
        <Placeholder animation='glow'>
          <Placeholder xs={12} style={{height: '180px'}}/>
        </Placeholder>        
      <Card.Body className='px-0'>
        <Placeholder as={Card.Subtitle} animation="glow">
          <Placeholder xs={6} />
        </Placeholder>
        <Placeholder as={Card.Title} animation="glow" className='my-0'>
          <Placeholder xs={8} />
        </Placeholder>
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={4} />
        </Placeholder>
      </Card.Body>
    </Card>
  </SwiperSlide>  
]

  return (
    <>
    <Container>
        <Row className='my-3 m-1 justify-content-md-center'>
          <Col lg="7" md="8" xs='12' className='border' style={{borderRadius: '20px'}}>
            <Row className="justify-content-md-center justify-content-sm-center align-items-center">
              <Col xs='2' className='p-0 text-center'><Button className="btn-cat" style={{backgroundColor: 'transparent'}}><img className="cat_icon"src={disco_icon} alt='disco_icon' onClick={handleFilter('disco')}/><p className='cat_text'>Nightclub</p></Button></Col>
              <Col xs='2' className='p-0 text-center'><Button className="btn-cat" style={{backgroundColor: 'transparent'}}><img className="cat_icon"src={bar_icon} alt='bar_icon' onClick={handleFilter('bar')}/><p className='cat_text'>Bar</p></Button></Col>
              <Col xs='2' className='p-0 text-center'><Button className="btn-cat" style={{backgroundColor: 'transparent'}}><img className="cat_icon"src={spa_icon} alt='spa_icon'  onClick={handleFilter('spa')}/><p className='cat_text'>Spa</p></Button></Col>
              <Col xs='2' className='p-0 text-center'><Button className="btn-cat" style={{backgroundColor: 'transparent'}}><img className="cat_icon"src={karaoke_icon} alt='karaoke_icon' onClick={handleFilter('karaoke')}/><p className='cat_text'>Karaoke</p></Button></Col>
              <Col xs='2' className='p-0 text-center'><Button className="btn-cat" style={{backgroundColor: 'transparent'}}><img className="cat_icon"src={hotel_icon} alt='hotel_icon' onClick={handleFilter('hotel')}/><p className='cat_text'>Hotel</p></Button></Col>
            </Row>
          </Col>
        </Row>
        <Row className='justify-content-end mx-1'>
        <Button variant="warning" className='nav-btn nav-btn-prev' onClick={() => swiperRef.current.slidePrev()}> <FontAwesomeIcon icon={faLessThan} size="sm" /></Button>
        <Button variant="warning" className='nav-btn nav-btn-next' onClick={() => swiperRef.current.slideNext()}><FontAwesomeIcon icon={faGreaterThan} size="sm" /></Button>
         </Row>
        <Row className='my-3 mx-1'>
        <Swiper
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            slidesPerView={4}
            spaceBetween={20}
            scrollbar={{
                hide: true,
              }}
              modules={[Scrollbar]}
            className="mySwiper"
            breakpoints={{
                0: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },  
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                }
              }}
        >
            {listVenues.length > 0 ?
            
              listVenues : listPlaceholder

           }
            
        </Swiper>
        </Row>  
    </Container>
    </>
  )
}

export default Venue