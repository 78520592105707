import React from 'react'
import { Col, Container, Row, Card, Button} from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import venue_icon from '../components/assets/distance.svg'
import clock_icon from '../components/assets/calendar_month.svg'
import axios from 'axios';
import parse from 'html-react-parser';

import { Helmet } from 'react-helmet';

const EventDetail = () => {
    const location = useLocation();
    const data = location.state;
    const {REACT_APP_API_URL} = process.env
    axios.defaults.baseURL = REACT_APP_API_URL;
    const navigate = useNavigate();


    const phoneCall = () => {
      window.open('tel:' + data.phone);
    }

    const toEvents = () => {
      navigate('/events');
    }

   
    console.log(data)

  return (
    <Container>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{data.venue_event_title}</title>
            <meta name="keywords" content={data.venue_event_meta_key} />
            <meta name="description" content={data.venue_event_meta_desc} />
            <link rel="canonical" href={window.location.href } />
            <meta name="robots" content="INDEX, FOLLOW"></meta>
        </Helmet>
         <Row className='my-3 text-center justify-content-md-center'>
            <h1>EVENT DETAIL</h1>
            <h3 style={{color: 'grey'}}>Jelajahi Malam Ini</h3>
        </Row>
        <Row className='my-3 mb-5'>
          <Col>
              <Card bg='dark'
                    key='dark'
                    text='white'>
                    <Row>
                      <Col lg="6" md="6" xs="12">
                        <Card.Img src={REACT_APP_API_URL + 'uploads/venue/events/' + data.venue_event_link} alt={data.venue_event_ID} />
                      </Col>
                      <Col lg="6" md="6" xs="12">
                        <Card.Body>
                          <Card.Title style={{fontWeight: '700'}}>{data.venue_event_title}</Card.Title>
                          <hr/>
                          <Card.Subtitle style={{fontWeight: '700'}} className='mt-3'><img src={venue_icon} className='me-3' alt='venue_icon'/>{data.name.toUpperCase()}</Card.Subtitle>
                          <Card.Subtitle className='mt-3 ms-4 text-white'>{data.location}</Card.Subtitle>
                          <Card.Subtitle className='mt-3 ms-4 text-white'>{data.description1}</Card.Subtitle>
                          <Card.Subtitle className='mt-3 ms-4 text-white'>{data.domisili}</Card.Subtitle>
                          <Card.Subtitle className='mt-3 text-white'><img src={clock_icon} className='me-3' alt='clock_icon'/>{data.venue_event_date}</Card.Subtitle>
                          <hr/>
                          <Card.Text>
                            {parse(data.venue_event_desc)}
                            <Row className='my-2'>
                              <Col className='d-grid gap-2'><Button className='btn-warning' onClick={phoneCall} style={{fontWeight: '700'}}>Kontak Kami</Button></Col>
                              <Col className='d-grid gap-2'><Button className='btn-dark' onClick={toEvents} style={{fontWeight: '700'}}>Event Lainnya</Button></Col>
                            </Row>
                          </Card.Text>
                        </Card.Body>        
                      </Col>
                    </Row>  
              </Card>
          </Col>
        </Row>
    </Container>
  )
}

export default EventDetail