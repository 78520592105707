import React, {useEffect, useState} from 'react'
import { Container, Row, Col, ListGroup, Card } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import axios from 'axios'

const TermCondition = () => {
    const [setting, setSetting] = useState([]);
    const {REACT_APP_API_URL} = process.env
    axios.defaults.baseURL = REACT_APP_API_URL;

    useEffect(() => {
      getMetaData()
      window.scrollTo(0, 0);
    }, []);

    const getMetaData = async () => {
      const response = await axios
      .get("settings/7", { headers: {
        "content-type": "application/json"
      }});
      setSetting(response.data);
  }

  return (
    <>
    <Helmet>
          <meta charSet="utf-8" />
          <title>{setting.meta_title}</title>
          <meta name="keywords" content={setting.meta_key} />
          <meta name="description" content={setting.meta_desc} />
          <link rel="canonical" href={window.location.href} />
          <meta name="robots" content="INDEX, FOLLOW"></meta>
          </Helmet>
    <Container>
        <Row className='my-1 text-center justify-content-md-center'>
            <Col>
            <h3>Term and Condition</h3>
            </Col>
        </Row>
        <Row className='mb-3 px-2'>
            <Col>
            <Card bg='dark'
                  text='white'>
                    <Card.Body><Card.Text>
                    <ListGroup as="ol" numbered>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"  
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">Acceptance of Terms</div>
                            By accessing our website or using our application, you agree to abide by these terms and conditions.
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">User Eligibility</div>
                            Users must be at least 21 years old Male or have parental/guardian consent to use our services.
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">User Account</div>
                            You are responsible for maintaining the confidentiality of your account information and agree to notify us of any
                            unauthorized use.
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"  
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">Content Ownership</div>
                            We retain the rights to the content provided on the website and application. Users may not reproduce or distribute it
                            without permission. 
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">User Conduct</div>
                            Users must not engage in illegal activities, harass others, or violate any applicable laws while using our services
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">Privacy Policy</div>
                            Our privacy policy outlines how we collect, use, and protect your personal information. By using our services, you agree
                            to the terms of our privacy policy.
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">Intellectual Property</div>
                            All trademarks, logos, and intellectual property on our website and application are the property of their respective owners
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">Disclaimer of Warranty</div>
                            We provide our services "as is," without any warranty or guarantee. We are not liable for any damages resulting from the
                            use of our services
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">Limitation of Liability</div>
                            Our liability is limited to the fullest extent permitted by law. We are not responsible for any indirect, incidental, or
                            consequential damages.
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">Termination of Services</div>
                            We reserve the right to terminate or suspend services without notice if users violate these terms
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">Updates to Terms</div>
                            We may update these terms periodically, and it is your responsibility to review them. Continued use of our services
                            implies acceptance of any changes.
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">Governing Law</div>
                            These terms are governed by the laws of UU ITE Tahun 2016. Any disputes will be resolved in the appropriate courts of
                            UU ITE Tahun 2016.
                            </div>
                        </ListGroup.Item>
                        </ListGroup>
                        <Row className='mt-2 text-white'>
                            <Col>By downloading our website and application, you acknowledge and agree to these terms and conditions.</Col>
                        </Row>
                    </Card.Text></Card.Body>
            </Card>
            </Col>
        </Row>
    </Container>
    </>
  )
}

export default TermCondition