import React, {useState, useEffect} from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Placeholder } from 'react-bootstrap';

// import Pagination from 'react-bootstrap/Pagination';

// import required modules
import { Container } from 'react-bootstrap';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import CustomPagination from '../CustomPagination';

const Blog = () => {
    const [blogs, setBlogs] = useState([]);
    const {REACT_APP_API_URL} = process.env
    axios.defaults.baseURL = REACT_APP_API_URL;
    const navigate = useNavigate();
    
    const [currentPage, setCurrentPage] = useState(1); // set the current page
    const pageSize = 9; // show row in table

    useEffect(() => {
        axios
          .get("pubblogs", { headers: {
            "content-type": "application/json"
          }})
          .then((result) => {

            const newPost = result.data.map((blogs) => {
              return {
                ...blogs,
                active: true
              }
            })  

            setBlogs(newPost);
          })
          .catch((error) => console.log(error));
      }, []);

      const paginatedData = blogs.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
      );

      const blogDetail = (data) => (e) => {
        data.blog_permalink !== null ? navigate('/blog/'+ data.blog_permalink, {state: data}) : navigate('/blogDetail', {state: data});
      }   

    const listBlogs = paginatedData.map((data, index) => {
        return (
          
          data.active && (
            <Col md="4" xs="6" key={index}>
                <Card bg='dark'
                      key='dark'
                      text='white' className='blog-card m-1'>
                    <Card.Img className="p-1" src={REACT_APP_API_URL + 'uploads/blog/' + data.blog_img_link} alt={data.blog_ID} />
                    <Card.Body style={{padding: '20px 0 0 0'}}>
                        <Card.Subtitle style={{fontSize: "12px"}}>{data.blog_date}</Card.Subtitle>
                        <Card.Title style={{fontSize: "16px"}}>{data.blog_title.length > 65 ? data.blog_title.substring(0,65) + '...' : data.blog_title}</Card.Title>
                        <div className='text-center d-grid gap-2 pt-2'>
                        <Button className='btn-blog' onClick={blogDetail(data)} style={{fontWeight: '700'}} size='sm'>Baca Selengkapnya</Button>
                        </div>
                    </Card.Body>
                </Card>    
            </Col>
            
          )

        );
    });

    const placeholder = [
      <Col md="4" xs="6">  
        <Card bg='dark'
                  key='dark'
                  text='white' className='event-card m-1'>
            <Placeholder animation='glow'>
              <Placeholder xs={12} style={{height: '300px'}}/>
            </Placeholder>        
          <Card.Body>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={6} />
            </Placeholder>
            <Placeholder as={Card.Subtitle} animation="glow">
              <Placeholder xs={4} />
            </Placeholder>
            <Placeholder as={Card.text} animation="glow">
              <Placeholder xs={12} />
              <Placeholder xs={12} />
              <Placeholder xs={12} />
            </Placeholder>
              <Placeholder.Button variant="btn-dark" className='my-2' xs={6} md={4}/>
          </Card.Body>
        </Card>
      </Col>
    ]


    const listPlaceholder = [];

    for (let i = 0; i < 6; i++)
    {
      listPlaceholder.push(placeholder);
    }  
     

  return (
    <>
    <Container>
        <Row className='my-3 text-center justify-content-sm-center'>
            <h1>Blog</h1>
            <h3 style={{color: 'grey'}}>Cari Tahu Informasi Terkini Seputar Nightlife</h3>
        </Row>
        <Row className='my-3'>
            {listBlogs.length !== 0 ? listBlogs : listPlaceholder}
            
        </Row>  

        <Row className='my-3'>
            <Col xs="12" md="12">
                <div className='d-flex justify-content-sm-center'>
                {blogs.length > 0 &&
                        <>
                          <CustomPagination
                            itemsCount={blogs.length}
                            itemsPerPage={pageSize}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            alwaysShown={true}
                          />
                        </>
                      }
                </div>
            </Col>
        </Row>
    </Container>
    </>
  )
}

export default Blog