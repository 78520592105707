import { Container, ListGroup } from 'react-bootstrap'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from './assets/NB-Logo-1.png'

import { NavLink, useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';

import playstore from './assets/playstore.png'

const Footer = () => {
    const navigate = useNavigate();
    const venueCategory = (data) => (e) => {
        e.preventDefault();
        // console.log(data);
        navigate('/discover', {state: data});
        window.scrollTo(0, 0)
      }

  return (
    <>
    <Container>
        <Row className='mx-1 mb-2'> 
            <Col lg="3" md="12" xs="12" className='my-2 text-center'><NavLink to="/"><img
                        src={logo}
                        className='logo-footer pt-3'
                        alt="NB Logo"
                        style={{maxWidth: "14em"}}
                    /></NavLink><hr className='d-flex d-lg-none'/></Col>
            <Col lg="3" md="3" xs="6" className='my-2'><h5 className="text-uppercase footer_title" style={{color: 'darkgray'}}>Tentang Night Butterfly</h5>
                <ul className="list-unstyled footer_subtitle">
                    <li><NavLink to="/about">Tentang Kami</NavLink></li>
                    <li><NavLink to="/">Kontak Kami</NavLink></li>
                    <li><NavLink to="/termcondition">Syarat dan Ketentuan</NavLink></li>
                    <li><NavLink to="/termservice">Persyaratan Layanan</NavLink></li>
                    <li><NavLink to="/privacypolicy">Kebijakan Pribadi</NavLink></li>
                    <li><NavLink to="/blogs">Blog</NavLink></li>
                </ul></Col>
            <Col lg="2" md="3" xs="6" className='my-2'><h5 className="text-uppercase footer_title" style={{color: 'darkgray'}}>Kategori</h5>
                <ul className="list-unstyled footer_subtitle">
                    <li><a href="#!" onClick={venueCategory({type: 'disco', city_name: ''})}>Nightclub</a></li>
                    <li><a href="#!" onClick={venueCategory({type: 'bar', city_name: ''})}>Bar & Lounge</a></li>
                    <li><a href="#!" onClick={venueCategory({type: 'spa', city_name: ''})}>Spa</a></li>
                    <li><a href="#!" onClick={venueCategory({type: 'karaoke', city_name: ''})}>Karaoke</a></li>
                    <li><a href="#!" onClick={venueCategory({type: 'hotel', city_name: ''})}>Hotel</a></li>
                    <li><NavLink to="/events">Events</NavLink></li>
                </ul></Col>
            <Col lg="2" md="3" xs="6" className='my-2'><h5 className="text-uppercase footer_title" style={{color: 'darkgray'}}>Lain-lain</h5>
                <ul className="list-unstyled footer_subtitle">
                   
                    
                    <li><NavLink to="/nearby">Nearby</NavLink></li>
                    <li><NavLink to="/">New Features</NavLink></li>
                    <li><NavLink to="/">Career</NavLink></li>
                </ul></Col>
            <Col lg="2" md="3" xs="6" className='my-2'>
                 <h5 className="text-uppercase footer_title" style={{color: 'darkgray'}}>Ikuti Kami</h5>
                <ListGroup horizontal>
                    <ListGroup.Item ><a href='https://www.facebook.com/profile.php?id=61553621761039' target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} className='icon-size' style={{verticalAlign: 'middle'}}/></a></ListGroup.Item>
                    <ListGroup.Item ><a href='https://www.youtube.com/@NightButterflyOfficial' target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube} className='icon-size' style={{verticalAlign: 'middle'}}/></a></ListGroup.Item>
                    <ListGroup.Item ><a href='https://www.tiktok.com/@nightbutterfly.official' target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={faTiktok} className='icon-size' style={{verticalAlign: 'middle'}}/></a></ListGroup.Item>
                    <ListGroup.Item ><a href='https://www.instagram.com/nightbutterfly.official/' target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} className='icon-size' style={{verticalAlign: 'middle'}}/></a></ListGroup.Item>
                    <ListGroup.Item ><a href='https://twitter.com/NightB_Official' target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} className='icon-size' style={{verticalAlign: 'middle'}}/></a></ListGroup.Item>
                    
                </ListGroup>
                <h5 className="text-uppercase footer_title mt-2" style={{color: 'darkgray'}}>Download App</h5>
                <a href='https://play.google.com/store/apps/details?id=com.nb.dev.nightbutterfly' target='_blank' rel="noopener noreferrer">
                    <img src={playstore} alt='playstore_image' className='footer-playstore'></img>
                </a>
                   
            </Col>    
        </Row>
    </Container>
    <Container fluid>
        <Row className='text-center' style={{backgroundColor: '#424649'}}>
            <small className='text-white'>Copyright @ 2024 Night Butterfly </small>
        </Row>
    </Container>
    </>
  )
}

export default Footer