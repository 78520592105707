import React, {useEffect, useState} from 'react'

import Banner from '../components/home/Banner'
import Venue from '../components/home/Venue'
import Event from '../components/home/Event'
import Destination from '../components/home/Destination'

import { Helmet } from 'react-helmet';
import axios from 'axios';


const Home = () => {
  const [setting, setSetting] = useState([]);
  const {REACT_APP_API_URL} = process.env
  axios.defaults.baseURL = REACT_APP_API_URL;

  useEffect(() => {
    getMetaData()
    window.scrollTo(0, 0);
  }, []);

  const getMetaData = async () => {
    const response = await axios
    .get("settings/1", { headers: {
      "content-type": "application/json"
    }});
     setSetting(response.data);
 }

  return (
    <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{setting.meta_title}</title>
          <meta name="keywords" content={setting.meta_key} />
          <meta name="description" content={setting.meta_desc} />
          <link rel="canonical" href={window.location.href} />
          <meta name="robots" content="INDEX, FOLLOW"></meta>
          </Helmet>

        <Banner/>
        <Venue/>
        <Event/>
        <Destination/>
        
    </>
  )
}

export default Home