import React, {useState, useEffect} from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Card from 'react-bootstrap/Card';
import { Placeholder } from 'react-bootstrap';

import venue_icon from '../assets/where_to_vote.svg'
import clock_icon from '../assets/time-clock-circle.svg'

// import Pagination from 'react-bootstrap/Pagination';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationPin, faBuilding } from '@fortawesome/free-solid-svg-icons'

// import required modules
import { Container } from 'react-bootstrap';

import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomPagination from '../CustomPagination';

const Venue = () => {
    const [venues, setVenues] = useState([]);
    const {REACT_APP_API_URL} = process.env
    axios.defaults.baseURL = REACT_APP_API_URL;
    const navigate = useNavigate();
    const location = useLocation();
    const city = location.state;
    const [cities, setCities] = useState([]);
    const [searchFilter, setSearchFilter] = useState(city !== null ? city.type :''); // filter the search
    const [searchFilter2, setSearchFilter2] = useState(city !== null ? city.city_name :''); // filter the search
    // const [searchFilter3, setSearchFilter3] = useState(''); // filter the search
    const [currentPage, setCurrentPage] = useState(1); // set the current page
    const pageSize = 12; // show row in table

    useEffect(() => {
        axios
          .get("pubvenue", { headers: {
            "content-type": "application/json"
          }})
          .then((result) => {
            const newPost = result.data.map((venues) => {
              return {
                ...venues,
                active: true
              }
            })  
            // console.log(newPost)
            setVenues(newPost);
          })
          .catch((error) => console.log(error));

          axios
          .get("pubcity", { headers: {
            "content-type": "application/json"
          }})
          .then((result) => {
            const newPost = result.data.map((cities) => {
              return {
                ...cities,
                active: true
              }
            })  
            // console.log(newPost)
            setCities(newPost);
          })
          .catch((error) => console.log(error));
      }, [searchFilter, searchFilter2]);

      const handleFilter = (e) => {
        setSearchFilter(e.target.value);
      };

      const handleFilter2 = (e) => {
        setSearchFilter2(e.target.value);
      };

      // const handleFilter3 = (e) => {
      //   setSearchFilter3(e.target.value);
      // };
    
      const filteredData = venues.filter(
        (item) =>
          item.type.toLowerCase().includes(searchFilter.toLowerCase()) &&
          item.city_name.toLowerCase().includes(searchFilter2.toLowerCase())
      );
    
      const paginatedData = filteredData.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
      );

      const venueDetail = (data) => (e) => {
        data.permalink !== null ? navigate('/'+ data.permalink, {state: data}) : navigate('/venueDetail', {state: data});
      } 

      const listCities = cities.map((data, index) => {
          return (
            
            data.active && (
              <option value={data.city_name} key={index}>{data.city_name}</option>
            )

          );
      });

    const listVenues = paginatedData.map((data, index) => {
        return (
          
          data.active && (
            <Col md="3" xs="6" key={'venue-'+index}>
                <Card bg='dark'
                      key='dark'
                      text='white' onClick={venueDetail(data)} style={{cursor: 'pointer'}}>
                    <Card.Img src={REACT_APP_API_URL + 'uploads/venue/' + data.main_image} alt={data.id} />
                    <Card.Body className='px-0'>
                        <Card.Subtitle><img src={venue_icon} className='me-1' alt='venue_icon'/>{data.domisili}</Card.Subtitle>
                        <Card.Title  className='my-0'>{data.name.length > 23 ? data.name.substring(0,23) + '...' : data.name}</Card.Title>
                        <Card.Text>
                        <img src={clock_icon} className='me-1' alt='clock_icon'/>{data.opening_hour}
                        </Card.Text>
                    </Card.Body>
                </Card>    
            </Col>
          )

        );
    });

    const placeholder = [
              <Col md="3" xs="6">  
                <Card     bg='dark'
                          text='white'>
                    <Placeholder animation='glow'>
                      <Placeholder xs={12} style={{height: '180px'}}/>
                    </Placeholder>        
                  <Card.Body className='px-0'>
                    <Placeholder as={Card.Subtitle} animation="glow">
                      <Placeholder xs={6} />
                    </Placeholder>
                    <Placeholder as={Card.Title} animation="glow" className='my-0'>
                      <Placeholder xs={8} />
                    </Placeholder>
                    <Placeholder as={Card.Text} animation="glow">
                      <Placeholder xs={4} />
                    </Placeholder>
                  </Card.Body>
                </Card>
              </Col>  
  ]  

  const listPlaceholder = [];

    for (let i = 0; i < 8; i++)
    {
      listPlaceholder.push(placeholder);
    }

  return (
    <>
    <Container>
        <Row className='my-3 justify-content-end'>
            <Col xs="6" lg="3" md="4">
            <InputGroup className="mb-3">
                <InputGroup.Text className='label-selector'><FontAwesomeIcon icon={faBuilding} size="sm" /></InputGroup.Text>
                <Form.Select aria-label="Default select example" className='selector' value={searchFilter}
                        onChange={handleFilter}>
                <option value=''> Kategori</option>
                <option value="disco">Nightclub</option>
                <option value="bar">Bar & Lounge</option>
                <option value="karaoke">Karaoke</option>
                <option value="spa">Spa</option>
                <option value="hotel">Hotel</option>
                </Form.Select>
            </InputGroup>
            {/* <InputGroup className="mb-3">
                <InputGroup.Text className='label-selector'><FontAwesomeIcon icon={faBuilding} size="sm" /></InputGroup.Text>
                <Form.Control className="search-box" type="text" placeholder="Search Venue Name..."  value={searchFilter}
                        onChange={handleFilter}/>
            </InputGroup> */}
            </Col>
            <Col xs="6" lg="3" md="4">
            <InputGroup className="mb-3">
                <InputGroup.Text className='label-selector'><FontAwesomeIcon icon={faLocationPin} size="sm" /></InputGroup.Text>
                <Form.Select aria-label="Default select example" className='selector' value={searchFilter2}
                        onChange={handleFilter2}>
                <option value="">Lokasi</option>
                {listCities}
            </Form.Select>
            </InputGroup>
            
            </Col>
        </Row>
        <Row className='my-3 text-center justify-content-md-center'>
            <h1>Jelajahi</h1>
            <h3 style={{color: 'grey'}}>Apa yang kamu cari ?</h3>
        </Row>
        
        <Row className='my-5'>
        
            {listVenues.length !== 0 ? listVenues : listPlaceholder }
            
        </Row>  

        <Row className='my-3'>
            <Col xs="12" md="12">
                <div className='d-flex justify-content-sm-center'>
                {filteredData.length > 0 &&
                        <>
                          <CustomPagination
                            itemsCount={filteredData.length}
                            itemsPerPage={pageSize}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            alwaysShown={true}
                          />
                        </>
                      }
                </div>
            </Col>
        </Row>
    </Container>
    </>
  )
}

export default Venue