import React, { useState, useEffect } from "react";
import { GoogleMap, useLoadScript, MarkerF,InfoWindow } from "@react-google-maps/api";
import Card from 'react-bootstrap/Card';
import axios from "axios";
import { Placeholder,Row, Col, Offcanvas, Button, Carousel } from "react-bootstrap";

import hotel_pin from '../assets/hotel_pin.svg'
import disco_pin from '../assets/disco_pin.svg'
import bar_pin from '../assets/bar_pin.svg'
import karaoke_pin from '../assets/karaoke_pin.svg'
import spa_pin from '../assets/spa_pin.svg'
import { useNavigate } from 'react-router-dom';

import bar from '../assets/filter_Bar.png'
import disco from '../assets/filter_Nightclub.png'
import spa from '../assets/filter_Spa.png'
import karaoke from '../assets/filter_Karaoke.png'
import hotel from '../assets/filter_Hotel.png'
import rekomendasi from '../assets/filter_rekomendasi.png'
import filter from '../assets/filter_icon.png'


const GMapping = () => {

  const [posts, setPosts] = useState([]);
  const {REACT_APP_API_URL} = process.env
  axios.defaults.baseURL = REACT_APP_API_URL;
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [cities, setCities] = useState([]);
  const [searchFilter, setSearchFilter] = useState(''); // filter the search
  const [searchFilter2, setSearchFilter2] = useState('');
  const [active, setActive] = useState("");
  const [active2, setActive2] = useState("");
  const [images, setImages] = useState([]);
  
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  useEffect(() => {
    axios
      .get("pubvenue", { headers: {
        "content-type": "application/x-www-form-urlencoded"
      }})
      .then((result) => {
        const newPost = result.data.map((venue) => {
          return {
            ...venue,
            active: true
          }
        })  

        setPosts(newPost);
      })
      .catch((error) => console.log(error));

      axios
          .get("pubcity", { headers: {
            "content-type": "application/json"
          }})
          .then((result) => {
            const newPost = result.data.map((cities) => {
              return {
                ...cities,
                active: true
              }
            })  
            // console.log(newPost)
            setCities(newPost);
          })
          .catch((error) => console.log(error));

          const listener = e => {
            if (e.key === "Escape") {
              setSelectedPark(null);
            }
          };
          window.addEventListener("keydown", listener);
      
          return () => {
            window.removeEventListener("keydown", listener);
          };
  }, [searchFilter, searchFilter2]);

  const handleFilter = (value) => (e) => {
    // e.target.value !== undefined &&
    // setSearchFilter(...e.target.value);
    //console.log(value)
    setSearchFilter(value)
    setActive(e.target.id);
  };

  const handleFilter2 = (e) => {
    setSearchFilter2(e.target.value);
    setActive2(e.target.id);
  };

  const handleReset = () => {
    setSearchFilter('');
    setSearchFilter2('');
    console.log('active', searchFilter)
    console.log('active2', searchFilter2)
  }

  const filteredData = posts.filter(
    (item) =>
      item.type.toLowerCase().includes(searchFilter.toLowerCase()) &&
      item.city_name.toLowerCase().includes(searchFilter2.toLowerCase())
  );

  const listCities = cities.map((data, index) => {
    return (
      
      data.active && (
        <Col md="6" xs="6" className="mb-2" key={'city-'+index}>
              <div className='text-center d-grid gap-2'>
              <Button key={index} id={data.city_ID} className={active2 === data.city_ID ? "btn-filter active" : "btn-filter"} onClick={handleFilter2} value={data.city_name}>{data.city_name}</Button>
              </div>
        </Col>
      )

    );
});

  const [selectedPark, setSelectedPark] = useState(null);

  const iconList = {
    hotel: hotel_pin,
    spa: spa_pin,
    karaoke: karaoke_pin,
    bar: bar_pin,
    disco: disco_pin
  }

  

  const mapRef = React.useRef();

  const mapStyles = {
    width: '100%',
    height: '620px'
  };
  
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
    
  }, []);
  
  const center = {
    lat:  -6.200000, // default latitude
    lng: 106.816666, // default longitude
  };

  const placeholder = [
   
      
    <Placeholder animation='glow'>
      <Placeholder xs={12} style={{height: '620px'}}/>
    </Placeholder>        

  ]  

  if (loadError) return "Error";
  
  if (!isLoaded) return placeholder;

  const venueDetail = (data) => (e) => {
    navigate('/venueDetail', {state: data});
  } 

  const mapLoad = filteredData.map((park) => {
    return (
      
      <MarkerF
          key={'park-'+park.id}
          position={{
            lat: park.lat,
            lng: park.lng
          }}
          onClick={() => {
            setSelectedPark(park);
            const getVenueImages = async () => {
              axios
              .get("venimages/"+ park.id, { headers: {
                "content-type": "application/json"
              }})
              .then((result) => {
                const newPost = result.data.map((images) => {
                  return {
                    ...images,
                    active: true
                  }
                })  
                console.log(newPost);
                setImages(newPost);
              })
              .catch((error) => console.log(error));
            }
            getVenueImages();
            console.log(iconList[park.type])
          }}
          icon={{
            url: iconList[park.type],
            scaledSize: new window.google.maps.Size(40, 40)
          }}
          
        />

    );
  });


  
  return (
    <>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton > 
          <Offcanvas.Title ><h3>Filter</h3></Offcanvas.Title>
        </Offcanvas.Header>
        <hr />
        <Offcanvas.Body>
          <Row>
            <h5 className="mb-3" style={{color: 'grey'}}>Pilih kategori yang kamu inginkan</h5>
            <Col md="6" xs="6" className="mb-2">
              <div className='text-center d-grid gap-2'>
              <Button key={1} id={1} className={active === "1" ? "btn-filter active" : "btn-filter"} onClick={handleFilter('bar')}><img className="cat_icon" src={bar} alt='bar_filter'/></Button>
              </div>
            </Col>
            <Col md="6" xs="6" className="mb-2">
              <div className='text-center d-grid gap-2'>
              <Button key={2} id={2} className={active === "2" ? "btn-filter active" : "btn-filter"} onClick={handleFilter('disco')}><img className="cat_icon" src={disco} alt='disco_filter'/></Button>
              </div>
            </Col>
            <Col md="6" xs="6" className="mb-2">
              <div className='text-center d-grid gap-2'>
              <Button key={3} id={3} className={active === "3" ? "btn-filter active" : "btn-filter"} onClick={handleFilter('spa')}><img className="cat_icon" src={spa} alt='spa_filter'/></Button>
              </div>
            </Col> 
            <Col md="6" xs="6" className="mb-2">
              <div className='text-center d-grid gap-2'>
              <Button key={4} id={4} className={active === "4" ? "btn-filter active" : "btn-filter"} onClick={handleFilter('karaoke')}><img className="cat_icon" src={karaoke} alt='karaoke_filter'/></Button>
              </div>
            </Col>
            <Col md="6" xs="6" className="mb-2">
              <div className='text-center d-grid gap-2'>
              <Button key={5} id={5} className={active === "5" ? "btn-filter active" : "btn-filter"} onClick={handleFilter('hotel')}><img className="cat_icon" src={hotel} alt='hotel_filter'/></Button>
              </div>
            </Col>
            <Col md="6" xs="6" className="mb-2">
              <div className='text-center d-grid gap-2'>
              <Button key={6} id={6} className={active === "6" ? "btn-filter active" : "btn-filter"} onClick={handleFilter('rekomendasi')}><img className="cat_icon" src={rekomendasi} alt='rekomendasi_filter'/></Button>
              </div>
            </Col>
          </Row>
          <hr/>
          <Row>
            <h5 className="mb-3" style={{color: 'grey'}}>Cari di kota yang kamu kunjungi</h5>
            {listCities}
          </Row>
          <hr/>
          <Row>
            <Col md="12" xs="12" className="mb-2">
              <div className='text-center d-grid gap-2'>
              <Button className="btn-filter" onClick={handleReset}>Reset</Button>
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
      <Col>
      <Button variant="light" onClick={handleShow} className='btn-map-filter'>
       <img className="cat_icon" src={filter} alt='filter_icon'/>
      </Button>
      <GoogleMap
        mapContainerStyle={
          mapStyles
        }
        center={center}
        zoom={15}
        onLoad={onMapLoad}
      >
      {mapLoad}

      {selectedPark && (
        <InfoWindow
          onCloseClick={() => {
            setSelectedPark(null);
          }}
          position={{
            lat: selectedPark.lat,
            lng: selectedPark.lng
          }}
        >
            
            <Card     
                  bg='light'
                  key='dark'
                  className="map-venue-img"
                  text='white'>
                  <Carousel data-bs-theme="dark" controls={true}  indicators={true} className="map-venue-img">
                    <Carousel.Item >
                      <img
                      className="map-venue-img"
                        src={REACT_APP_API_URL + 'uploads/venue/' + selectedPark.main_image} 
                        alt={selectedPark.id}
                      />
                    </Carousel.Item>
                    { images.map((data) => {
                        return (
                      <Carousel.Item>
                        <img
                        className="map-venue-img"
                          src={REACT_APP_API_URL + 'uploads/venue/gallery/' + data.vi_img_link} 
                          alt={data.vi_ID} 
                        />
                      </Carousel.Item>
                    )} ) }
                  </Carousel>
                  <Card.Body  onClick={venueDetail(selectedPark)} style={{cursor: 'pointer'}}>
                      <Card.Title style={{color: '#000'}}>{selectedPark.name}</Card.Title>
                      <Card.Subtitle>{selectedPark.description1}</Card.Subtitle>
                      <Card.Text>
                      {selectedPark.opening_hour}
                      </Card.Text>
                  </Card.Body>
            </Card>    
        </InfoWindow>
      )}
    
      </GoogleMap>
      
    </Col>
    </>
    
  )
} 

export default GMapping