import React, {useState, useEffect, useRef} from 'react'
import Row from 'react-bootstrap/Row';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Placeholder } from 'react-bootstrap';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';


// import required modules
import { Scrollbar } from 'swiper/modules';
import { Container } from 'react-bootstrap';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGreaterThan, faLessThan } from '@fortawesome/free-solid-svg-icons'

import { slice } from 'lodash';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Event = () => {
    const [events, setEvents] = useState([]);
    const initialEvents = slice(events, 0, 8)
    const {REACT_APP_API_URL} = process.env
    axios.defaults.baseURL = REACT_APP_API_URL;
    const navigate = useNavigate();
    const swiperRef = useRef();

    useEffect(() => {
        axios
          .get("pubevent", { headers: {
            "content-type": "application/json"
          }})
          .then((result) => {
            const newPost = result.data.map((events) => {
              return {
                ...events,
                active: true
              }
            })  

            setEvents(newPost);
          })
          .catch((error) => console.log(error));
      }, []);

    //   const getEvents = async () => {
    //     const response = await axios.get('pubevent');
    //     setEvents(response.data);
    // }  

    const EventDetail = (data) => (e) => {
      data.venue_event_permalink !== null ? navigate('/event/'+ data.venue_event_permalink, {state: data}) : navigate('/eventDetail', {state: data});
    }  

    const listEvents = initialEvents.map((data) => {
        return (
          
          data.active && (
            <SwiperSlide key={'event-'+data.venue_event_ID}>
                    <Card bg='dark'
                        key='dark'
                        text='white' className='text-center'>
                        <Card.Img src={REACT_APP_API_URL + 'uploads/venue/events/' + data.venue_event_link} alt={data.venue_event_ID} style={{cursor: 'pointer'}} />
                        <Card.Body>
                            <Card.Title>{data.venue_event_title.length >17 ? data.venue_event_title.substring(0,19) + '...' : data.venue_event_title }</Card.Title>
                            <Card.Text>
                            <Button className='btn-dark' onClick={EventDetail(data)}>More Info</Button>
                            </Card.Text>
                        </Card.Body>
                    </Card>    
            </SwiperSlide>
          )

        );
    });

  const listPlaceholder = [
            <SwiperSlide key={'ph-event-'+1}>  
              <Card bg='dark'
                        key='dark'
                        text='white' className='text-center'>
                  <Placeholder animation='glow'>
                    <Placeholder xs={12} style={{height: '180px'}}/>
                  </Placeholder>        
                <Card.Body>
                  <Placeholder as={Card.Title} animation="glow">
                    <Placeholder xs={6} />
                  </Placeholder>
                    <Placeholder.Button variant="btn-dark" xs={4} />
                </Card.Body>
              </Card>
            </SwiperSlide>,
            <SwiperSlide key={'ph-event-'+2}>  
            <Card bg='dark'
                      key='dark'
                      text='white' className='text-center'>
                <Placeholder animation='glow'>
                  <Placeholder xs={12} style={{height: '180px'}}/>
                </Placeholder>        
              <Card.Body>
                <Placeholder as={Card.Title} animation="glow">
                  <Placeholder xs={6} />
                </Placeholder>
                  <Placeholder.Button variant="btn-dark" xs={4} />
              </Card.Body>
            </Card>
          </SwiperSlide>,
          <SwiperSlide key={'ph-event-'+3}>  
          <Card bg='dark'
                    key='dark'
                    text='white' className='text-center'>
              <Placeholder animation='glow'>
                <Placeholder xs={12} style={{height: '180px'}}/>
              </Placeholder>        
            <Card.Body>
              <Placeholder as={Card.Title} animation="glow">
                <Placeholder xs={6} />
              </Placeholder>
                <Placeholder.Button variant="btn-dark" xs={4} />
            </Card.Body>
          </Card>
        </SwiperSlide>,
        <SwiperSlide key={'ph-event-'+4}>  
        <Card bg='dark'
                  key='dark'
                  text='white' className='text-center'>
            <Placeholder animation='glow'>
              <Placeholder xs={12} style={{height: '180px'}}/>
            </Placeholder>        
          <Card.Body>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={6} />
            </Placeholder>
              <Placeholder.Button variant="btn-dark" xs={4} />
          </Card.Body>
        </Card>
      </SwiperSlide>  
  ]  

    
  return (
    <>
    <Container>
        <Row className='my-2 mx-1'>
            <h2 className='home-h1'>EVENTS</h2>
            <h3 className='home-h3'>Jelajahi Malam Dengan Mudah</h3>
        </Row>
        <Row className='justify-content-end mx-1'>
        <Button variant="warning" className='nav-btn nav-btn-prev' onClick={() => swiperRef.current.slidePrev()}> <FontAwesomeIcon icon={faLessThan} size="sm" /></Button>
        <Button variant="warning" className='nav-btn nav-btn-next' onClick={() => swiperRef.current.slideNext()}><FontAwesomeIcon icon={faGreaterThan} size="sm" /></Button>
         </Row>
        <Row className='my-2 mx-1'>
        <Swiper
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            slidesPerView={4}
            spaceBetween={20}
            scrollbar={{
                hide: true,
              }}
              modules={[Scrollbar]}
            className="mySwiper"

            breakpoints={{
                0: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },  
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                }
              }}
        >

            {listEvents.length > 0 ?
            
            listEvents : listPlaceholder
            
            }
           
            
        </Swiper>
        </Row>  
    </Container>
    </>
  )
}

export default Event