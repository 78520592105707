import React, {useEffect, useState} from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import { Helmet } from 'react-helmet';
import axios from 'axios';

const About = () => {
    const [setting, setSetting] = useState([]);
    const {REACT_APP_API_URL} = process.env
    axios.defaults.baseURL = REACT_APP_API_URL;

    useEffect(() => {
      getMetaData()
      window.scrollTo(0, 0);
    }, []);

    const getMetaData = async () => {
      const response = await axios
      .get("settings/6", { headers: {
        "content-type": "application/json"
      }});
      setSetting(response.data);
  }

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      });
  
      const mapRef = React.useRef();
  
      const mapStyles = {
        width: '100%',
        height: '250px'
      };
      
      const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
      }, []);
  
      if (loadError) return "Error";
    
      if (!isLoaded) return "";

  return (
    <>
    <Helmet>
          <meta charSet="utf-8" />
          <title>{setting.meta_title}</title>
          <meta name="keywords" content={setting.meta_key} />
          <meta name="description" content={setting.meta_desc} />
          <link rel="canonical" href={window.location.href} />
          <meta name="robots" content="INDEX, FOLLOW"></meta>
          </Helmet>
    <Container>
        <Row className='my-3 text-center justify-content-md-center'>
            <h3>About Us</h3>
        </Row>
        <Row className='my-2 mx-1'>
            <Col style={{paddingRight: "0px", paddingLeft: "0px"}} key={1}>
                <Card bg='dark'
                        text='white'>
                        <Row className='mb-2'>
                        <Col lg="4" md="4" xs="12" className='mb-3'>
                            <GoogleMap
                                mapContainerStyle={
                                  mapStyles
                                }
                                center={{lat: -6.175439895155567, lng: 106.79356669824233}}
                                zoom={15}
                                onLoad={onMapLoad}
                              >
                                  
                                    
                                <MarkerF
                                  key={1}
                                  position={{
                                    lat: -6.175439895155567,
                                    lng: 106.79356669824233
                                  }}
                                  icon={{
                                    scaledSize: new window.google.maps.Size(40, 40)
                                  }}
                                  
                                />
                              

                              </GoogleMap>
                        </Col>
                        <Col lg="8" md="8" xs="12">
                            <Card.Body className='p-0'>
                            <Card.Text className='text-white' style={{fontSize: '20px'}}>
                                We started in October 2023 as a small team full of passion and ambition to continue growing with high-quality, professional, 
                                and reliable human resources in their fields. With our concept aimed at facilitating community life for both local and 
                                international users in obtaining and digesting the massive digital information in this modern era. Our office is currently 
                                located at Rukan Blue Dot Center, Jl. Gelong Baru Utara No. 8 Tomang West Jakarta.
                            </Card.Text>
                            
                            </Card.Body>        
                        </Col>
                        </Row>
                        <Row>
                            <Col>
                            <Card.Body className='p-0'>
                                <Card.Text className='text-white' style={{fontSize: '20px'}}>
                                We are the provider of real-time informative applications for nightlife entertainment based on smartphones under the trademark "NIGHT BUTTERFLY." 
                                The Night Butterfly application provides information on various nightlife entertainment venues, ranging from Nightclubs, Spas, Bars and Lounges, 
                                Karaoke, to Hotels. The main concept of this application is to make it easier for people who want to find entertainment venues in various cities 
                                in Indonesia.
                                </Card.Text>
                                <Card.Text className='text-white' style={{fontSize: '20px'}}>
                                Our primary target market is men aged 18 and above, but it also allows for women aged 18 and above, as the application includes categories such as 
                                bars & lounges and nightclubs, which can also cater to women. Currently, our application focuses specifically on Android users, but in the future, 
                                we will also create an Apple version for iPhone users.
                                </Card.Text>
                            </Card.Body>
                            </Col>
                        </Row>  
                </Card>
            </Col>
        </Row>
    </Container>
    </>
  )
}

export default About