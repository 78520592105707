import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import logo from './assets/NB-Logo-1.png'

import { NavLink } from 'react-router-dom';

const Header = () => {
    const openPlayStore = () => {
        window.open('https://play.google.com/store/apps/details?id=com.nb.dev.nightbutterfly', '_blank');
      }

  return (
    <>
        <Navbar className="bg-body-dark" bg="dark" data-bs-theme="dark" expand="lg">
            <Container>
                <Navbar.Brand href="/">
                    <img
                        src={logo}
                        width="110"
                        className="d-inline align-top logo-main"
                        alt="React Bootstrap logo"
                    />
                </Navbar.Brand>
                <Form className="d-flex d-lg-none">
                        <Button className='btn-nb-primary px-4' onClick={openPlayStore}>Download App</Button>
                </Form>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                <Nav
                    className="mx-auto my-2"
                    style={{ maxHeight: '100px' }}
                    navbarScroll
                >
                    <NavLink to='/discover' className="nav-link">Jelajahi</NavLink>
                    <NavLink to='/nearby'className="nav-link">Cari Sekitarmu</NavLink>
                    <NavLink to='/events' className="nav-link">Event</NavLink>
                    <NavLink to='/blogs' className="nav-link">Blog</NavLink>
                </Nav>
                <Form className="d-flex d-none d-lg-flex">
                    <Button className='btn-nb-primary px-4' onClick={openPlayStore}>Download App</Button>
                </Form>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </>
  )
}

export default Header