import React, {useState, useEffect} from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import call_icon from '../components/assets/call.svg'
import clock_icon from '../components/assets/time-clock-circle.svg'
import image_placehold from '../components/assets/image_placehold.png'
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";

import hotel_pin from '../components/assets/hotel_pin.svg'
import disco_pin from '../components/assets/disco_pin.svg'
import bar_pin from '../components/assets/bar_pin.svg'
import karaoke_pin from '../components/assets/karaoke_pin.svg'
import spa_pin from '../components/assets/spa_pin.svg'

import price_icon from '../components/assets/price_icon.svg'
import check_icon from '../components/assets/check_list.svg'

import axios from 'axios';
import { slice } from 'lodash';

import FsLightbox from 'fslightbox-react';

import { Helmet } from 'react-helmet';


const VenueDetail = () => {
    const location = useLocation();
    const data = location.state
    const {REACT_APP_API_URL} = process.env
    axios.defaults.baseURL = REACT_APP_API_URL;
    const navigate = useNavigate();
    const [events, setEvents] = useState([]);
    const [features, setfeatures] = useState([]);
    const [images, setImages] = useState([]);
    const initialImages = slice(images, 0, 4);
    const initialEvents = slice(events, 0, 4);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const id = data.id

    const [toggler, setToggler] = useState(false);

    

    useEffect(() => {
      const getVenueFeatures = async () => {
        axios
        .get("/features/"+ id, { headers: {
          "content-type": "application/json"
        }})
        .then((result) => {
          const newPost = result.data.map((features) => {
            return {
              ...features,
              active: true
            }
          })  
  
          setfeatures(newPost);
        })
        .catch((error) => console.log(error));
      }

      const getVenueEvent = async () => {
        axios
        .get("pubeventvenue/"+ id, { headers: {
          "content-type": "application/json"
        }})
        .then((result) => {
          const newPost = result.data.map((events) => {
            return {
              ...events,
              active: true
            }
          })  
  
          setEvents(newPost);
        })
        .catch((error) => console.log(error));
      }

      const getVenueImages = async () => {
        axios
        .get("venimages/"+ id, { headers: {
          "content-type": "application/json"
        }})
        .then((result) => {
          const newPost = result.data.map((images) => {
            return {
              ...images,
              active: true
            }
          })  
  
          setImages(newPost);
        })
        .catch((error) => console.log(error));
      }
      
      getVenueEvent();
      getVenueFeatures();
      getVenueImages();
    }, [id]);

    const EventDetail = (data) => (e) => {
      navigate('/eventDetail', {state: data});
    }       

    const listEvents = initialEvents.map((data, index) => {
      return (
        
        data.active && (
          <Col md="3" xs="6"  key={index} className='my-2'>
              <Card bg='dark'
                    key='dark'
                    text='white' className='text-center event-card' onClick={EventDetail(data)} style={{cursor: 'pointer'}}>
                  <Card.Img src={REACT_APP_API_URL + 'uploads/venue/events/' + data.venue_event_link} alt={data.venue_event_ID} />
                 
              </Card>    
          </Col>
        )

      );
  });

  const listFeatures = features.map((data, index) => {
    return (
      data.active && (
        <Col md="6" xs="6" key={index}>
          <Card.Subtitle className='mt-2'><img className="me-2" src={check_icon} alt='check_icon'/>{data.vf_title}</Card.Subtitle>
        </Col>
      )
    )
  })

  const listImages = initialImages.map((data, index) => {
    return (
      index % 2 === 0 ?
        <Col md="6" xs="6" className='pe-0' key={index}>
              <img className="venue_img_gallery img-fluid" src={REACT_APP_API_URL + 'uploads/venue/gallery/' + data.vi_img_link} alt={data.vi_ID}/>
        </Col>
      :
      <Col md="6" xs="6" className='ps-0' key={index}>
              <img className="venue_img_gallery img-fluid" src={REACT_APP_API_URL + 'uploads/venue/gallery/' + data.vi_img_link} alt={data.vi_ID}/>
        </Col>
    )
  })

  const listGallery = images.map((data, index) => {
    return (
        data.active && 
          <Col lg="4" md="4" xs="6" className='p-1'>
              <img className="img-fluid" src={REACT_APP_API_URL + 'uploads/venue/gallery/' + data.vi_img_link} alt={data.vi_ID} onClick={() => {
                setToggler(!toggler)
                }}/>
          </Col>
      )
    }
  )

  const listGallery2 = images.map((data, index) => {
    return (
        data.active && 
        <Col>
          <img className='img-fluid' src={REACT_APP_API_URL + 'uploads/venue/gallery/' + data.vi_img_link} alt={data.vi_ID} />
        </Col>
      )
    }
  )

    const phoneCall = () => {
      window.open('tel:' + data.phone);
    }

    const { isLoaded, loadError } = useLoadScript({
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    });

    const mapRef = React.useRef();

    const mapStyles = {
      width: '100%',
      height: '300px'
    };
    
    const onMapLoad = React.useCallback((map) => {
      mapRef.current = map;
    }, []);

    const iconList = {
      hotel: hotel_pin,
      spa: spa_pin,
      karaoke: karaoke_pin,
      bar: bar_pin,
      disco: disco_pin
    }

    if (loadError) return "Error";
  
    if (!isLoaded) return "";

    
  return (
    data !== null && (
    <Container>
      <Helmet>
          <meta charSet="utf-8" />
          <title>{data.name}</title>
          <meta name="keywords" content={data.meta_key} />
          <meta name="description" content={data.meta_desc} />
          <link rel="canonical" href={window.location.href } />
          <meta name="robots" content="INDEX, FOLLOW"></meta>
      </Helmet>
    <Row>
      <Carousel data-bs-theme="dark" controls={false}  indicators={false}>
        <Carousel.Item>
          <Row>
          <Col md="6" xs="12" className='pe-1'>
            <img
              className="venue_img img-fluid"
              src={REACT_APP_API_URL + 'uploads/venue/' + data.main_image} 
              alt={id}
            />
          </Col>
          <Col md="6" xs="12" className='p-0'>
            <Row>
              {
              listImages.length !== 0 ? listImages : 
              <>
              <Col md="6" xs="6" className='pe-0'>
                  <img className="venue_img_gallery img-fluid" src={image_placehold} alt='2'/>
              </Col>
              <Col md="6" xs="6" className='ps-0'>
                  <img className="venue_img_gallery img-fluid" src={image_placehold} alt='2'/>
              </Col>
              <Col md="6" xs="6" className='pe-0'>
                  <img className="venue_img_gallery img-fluid" src={image_placehold} alt='3'/>
              </Col>
              <Col md="6" xs="6" className='ps-0'>
                  <img className="venue_img_gallery img-fluid" src={image_placehold} alt='4'/>
              </Col>
              </>
              }
            </Row>
          </Col>  
          </Row>
          <Carousel.Caption className='text-end' style={{right: '1%', bottom: '-3%'}}>
            <Button variant='dark' onClick={handleShow}>More Images</Button>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </Row>    
    
    
        <Row className='my-3'>
          <Col>
              <Card bg='dark'
                    key='dark'
                    text='white'>
                    <Row>
                      <Col lg="6" md="6" xs="12">
                        <Card.Body>
                          <h1>{data.name}</h1>  
                          <Card.Subtitle className='mt-2' style={{color: 'grey'}}><img src={clock_icon} className='me-3' alt='clock_icon'/>{data.opening_hour}</Card.Subtitle>
                          <Card.Subtitle className='mt-2' style={{color: 'grey'}}><img src={call_icon} className='me-3' alt='call_icon'/>{data.phone} <Button className='ms-3 btn-warning text-white' onClick={phoneCall} size='sm'>Chat Us</Button></Card.Subtitle>
                          <Card.Subtitle className='mt-2' style={{color: 'grey'}}><img src={price_icon} className='me-3' alt='clock_icon'/>{data.description1}</Card.Subtitle>
                          <Card.Subtitle className='mt-2 text-white'>{data.location}</Card.Subtitle>
                          <Card.Subtitle className='mt-2 text-white'>{data.domisili}</Card.Subtitle>
                          <hr/>
                          <Card.Text>
                            {data.venue_event_desc}
                            <Row className='my-2'>
                            <GoogleMap
                                mapContainerStyle={
                                  mapStyles
                                }
                                center={{lat: data.lat, lng: data.lng}}
                                zoom={15}
                                onLoad={onMapLoad}
                              >
                                  
                                    
                                <MarkerF
                                  key={data.id}
                                  position={{
                                    lat: data.lat,
                                    lng: data.lng
                                  }}
                                  icon={{
                                    url: iconList[data.type],
                                    scaledSize: new window.google.maps.Size(40, 40)
                                  }}
                                  
                                />
                              

                              </GoogleMap>
                            </Row>
                          </Card.Text>
                        </Card.Body> 
                      </Col>
                      <Col lg="6" md="6" xs="12">
                        <Card.Body>
                          <Card.Title>Facility</Card.Title>
                          <hr/>
                          <Row>
                            {listFeatures.length !== 0 ? listFeatures : <Card.Subtitle className='mt-2'>No features available</Card.Subtitle> }
                          </Row>
                          <hr/>
                          <Card.Text>
                            <Card.Subtitle className='mt-3 text-white'>{data.description2}</Card.Subtitle>
                          </Card.Text>
                        </Card.Body>        
                      </Col>
                    </Row>  
              </Card>
          </Col>
        </Row>

        {listEvents.length > 0 
        ? 
        <Row className='my-5'>
          <h1>Event yang akan datang</h1>
          {listEvents}
        </Row>
        :
        <Row className='my-5'>
          <h1>Event yang akan datang</h1>
          <h3>Tidak ada event untuk saat ini</h3>
        </Row>
        }                            

      <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show} onHide={handleClose}
      >
      <Modal.Header style={{backgroundColor: "#2c3034"}}>
        <Modal.Title id="contained-modal-title-vcenter">
         {data.name} - Image Gallery
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{backgroundColor: '#212529'}}>
        <Row>
          {listGallery.length !== 0 ? listGallery : <Col>Tempat ini tidak ada gallery tambahan, maaf!</Col>}
        </Row>
			 <FsLightbox
          toggler={toggler}
          sources={listGallery2}
          zoomIncrement={1.0}
			  />
      </Modal.Body>
      <Modal.Footer style={{backgroundColor: '#2c3034'}}>
        <Button variant='dark' onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
    
    </Container>
    
    )
  )
}

export default VenueDetail;