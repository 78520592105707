import React, {useEffect, useState} from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Placeholder } from 'react-bootstrap';

// Import Swiper React components


// import Pagination from 'react-bootstrap/Pagination';



// import required modules
import { Container } from 'react-bootstrap';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CustomPagination from '../CustomPagination';


const Events = () => {
    const [events, setEvents] = useState([]);
    const {REACT_APP_API_URL} = process.env
    axios.defaults.baseURL = REACT_APP_API_URL;
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1); // set the current page
    const pageSize = 9; // show row in table

    useEffect(() => {
        axios
          .get("pubevent", { headers: {
            "content-type": "application/json"
          }})
          .then((result) => {
            const newPost = result.data.map((events) => {
              return {
                ...events,
                active: true
              }
            })  

            setEvents(newPost);
          })
          .catch((error) => console.log(error));
      }, []);

      const paginatedData = events.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
      );

      const EventDetail = (data) => (e) => {
        data.venue_event_permalink !== null ? navigate('/event/'+ data.venue_event_permalink, {state: data}) : navigate('/eventDetail', {state: data});
      }   

    const listEvents = paginatedData.map((data, index) => {
        return (
          
          data.active && (
            <Col md="4" xs="6"  key={index}>
                <Card bg='dark'
                      key='dark'
                      text='white' className='event-card m-1'>
                    <Card.Img className="p-3"src={REACT_APP_API_URL + 'uploads/venue/events/' + data.venue_event_link} alt={data.venue_event_ID} />
                    <Card.Body>
                        <Card.Title>{data.venue_event_title.length > 20 ? data.venue_event_title.substring(0,20) + '...' : data.venue_event_title }</Card.Title>
                        <Card.Subtitle style={{color: 'grey'}}>{data.venue_event_date} </Card.Subtitle>
                        <Card.Text style={{color: '#B6862D'}}>
                        {data.name}
                        </Card.Text>
                        <div className='text-center d-grid gap-2'>
                        <Button className='btn-dark' onClick={EventDetail(data)}> Info Lengkapnya</Button>
                        </div>
                    </Card.Body>
                </Card>    
            </Col>
          )

        );
    });

    const placeholder = [
      <Col md="4" xs="6">  
        <Card bg='dark'
                  key='dark'
                  text='white' className='text-center'>
            <Placeholder animation='glow'>
              <Placeholder xs={12} style={{height: '300px'}}/>
            </Placeholder>        
          <Card.Body>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={6} />
            </Placeholder>
              <Placeholder.Button variant="btn-dark" xs={4} />
          </Card.Body>
        </Card>
      </Col>
    ]


    const listPlaceholder = [];

    for (let i = 0; i < 6; i++)
    {
      listPlaceholder.push(placeholder);
    }  

  return (
    <>
    <Container>
        <Row className='my-3 text-center justify-content-md-center'>
            <h1>EVENTS</h1>
            <h3 style={{color: 'grey'}}>Jelajahi Malam Ini</h3>
        </Row>
        <Row className='my-3'>
            {listEvents.length !== 0 ? listEvents : listPlaceholder}
            
        </Row>  

        <Row className='my-3'>
            <Col xs="12" md="12">
                <div className='d-flex justify-content-sm-center'>
                {events.length > 0 &&
                        <>
                          <CustomPagination
                            itemsCount={events.length}
                            itemsPerPage={pageSize}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            alwaysShown={true}
                          />
                        </>
                      }
                </div>
            </Col>
        </Row>
    </Container>
    </>
  )
}

export default Events