import { Routes, Route } from "react-router-dom";

import Home from './pages/Home';
import Discover from './pages/Discover';
import Nearby from './pages/Nearby';
import Events from './pages/Events';
import Blogs from './pages/Blogs';

import Header from './components/Header'
import Footer from './components/Footer'
import EventDetail from "./pages/EventDetail";
import BlogDetail from "./pages/BlogDetail";
import VenueDetail from "./pages/VenueDetail";
import About from "./pages/About";
import TermCondition from "./pages/TermCondition";
import TermService from "./pages/TermService";
import Privacy from "./pages/Privacy";



const App = () => {
  return (
    <>
      <Header /> 
          <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/discover" element={<Discover />} />
                    <Route exact path="/venueDetail" element={<VenueDetail />} />
                    <Route exact path="/:permalink" element={<VenueDetail />} />
                    <Route exact path="/nearby" element={<Nearby />} />
                    <Route exact path="/events" element={<Events />} />
                    <Route exact path="/eventDetail" element={<EventDetail />} />
                    <Route exact path="/event/:permalink" element={<EventDetail />} />
                    <Route exact path="/blogs" element={<Blogs />} />
                    <Route exact path="/blogDetail" element={<BlogDetail />} />
                    <Route exact path="/blog/:permalink" element={<BlogDetail />} />
                    <Route exact path="/about" element={<About />} />
                    <Route exact path="/termcondition" element={<TermCondition />} />
                    <Route exact path="/termservice" element={<TermService />} />
                    <Route exact path="/privacypolicy" element={<Privacy />} />
          </Routes>
      <Footer />    
    </>    
  );
}

export default App;
