import React, {useEffect, useState} from 'react'
import { Container, Row, Col, ListGroup, Card } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import axios from 'axios'

const Privacy = () => {
    const [setting, setSetting] = useState([]);
    const {REACT_APP_API_URL} = process.env
    axios.defaults.baseURL = REACT_APP_API_URL;

    useEffect(() => {
      getMetaData()
      window.scrollTo(0, 0);
    }, []);

    const getMetaData = async () => {
      const response = await axios
      .get("settings/9", { headers: {
        "content-type": "application/json"
      }});
      setSetting(response.data);
  }
  return (

    <>
     <Helmet>
          <meta charSet="utf-8" />
          <title>{setting.meta_title}</title>
          <meta name="keywords" content={setting.meta_key} />
          <meta name="description" content={setting.meta_desc} />
          <link rel="canonical" href={window.location.href} />
          <meta name="robots" content="INDEX, FOLLOW"></meta>
          </Helmet>
    <Container>
        <Row className='my-1 text-center justify-content-md-center'>
            <Col>
            <h3>Privacy Policy</h3>
            </Col>
        </Row>
        <Row className='mb-3 px-2'>
            <Col>
            <Card bg='dark'
                  text='white'>
                    <Card.Body><Card.Text>
                    <Row className='mb-2 text-white'>
                        <Col>This Privacy Policy outlines how Night Butterfly ("we," "us," or "our") collects, uses, discloses, and protects the personal
                            information of users ("you" or "your") of our Night Butterfly ("the App"). By using the App, you agree to the terms outlined
                            in this Privacy Policy.</Col>
                    </Row>    
                    <ListGroup as="ol" numbered>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"  
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">Information We Collect:</div>
                            - Personal Information: We may collect information that personally identifies you, such as your name, email address, or
                            other contact information. <br/>
                            - Usage Data: We may collect information about how you interact with the App, including usage patterns, preferences,
                            and device information.
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">How We Use Your Information:</div>
                            - We use your personal information to provide and improve the App's functionality. <br/>
                            - Your data may be used for communication, including responding to inquiries or providing updates related to the App. <br/>
                            - We may use anonymized and aggregated data for analytical purposes.
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"  
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">Data Sharing</div>
                            - We do not sell, trade, or rent your personal information to third parties.<br/>
                            - We may share your information with third-party service providers who assist us in operating the App and providing
                            services.
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">Data Security:</div>
                            - We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or
                            destruction.<br/>
                            - While we strive to protect your data, no method of transmission over the internet or electronic storage is completely
                            secure.
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">User Choices:</div>
                            - You can choose not to provide certain information, but it may limit your ability to use specific features of the App.<br/>
                            - You may opt-out of receiving promotional communications from us by following the instructions provided in those
                            communications.
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">Children's Privacy:</div>
                            - The App is not intended for children and teenager under the age of 21. We do not knowingly collect personal information
                            from children.
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold"> Changes to this Privacy Policy:</div>
                            - We may update this Privacy Policy periodically. Any changes will be effective when posted on the App.
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">Contact Us:</div>
                            - If you have any questions or concerns about this Privacy Policy, please contact us at adminsupport@nightbutterfly.net
                            </div>
                        </ListGroup.Item>
                        </ListGroup>
                        <Row className='my-2 text-white'>
                            <Col>By using the App, you acknowledge that you have read and understood this Privacy Policy and agree to its terms. It is
                                your responsibility to review this Privacy Policy periodically for updates.
                            </Col>
                        </Row>
                        <Row className='mt-5 text-white'>
                            <Col>
                                <Card.Title>
                                    Night Butterfly <br/>
                                    Rukan Blue Dot Center    
                                </Card.Title>
                                Jl. Gelong Baru Utara No.8, RT.8/RW.3, Tomang, <br/>
                                Kec. Grogol petamburan, Kota Jakarta Barat,<br/>
                                Daerah Khusus Ibukota Jakarta 11440
                            </Col>
                        </Row>
                    </Card.Text></Card.Body>
            </Card>
            </Col>
        </Row>
    </Container>
    </>
  )
}

export default Privacy