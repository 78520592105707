import React, {useState, useEffect} from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Card from 'react-bootstrap/Card';
import {Button, Placeholder} from 'react-bootstrap';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import banner from '../assets/mid_banner.png'
import playstore from '../assets/playstore.png'

import Carousel from 'react-bootstrap/Carousel';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';


// import required modules
// import { Scrollbar } from 'swiper/modules';
import { Navigation } from 'swiper/modules';
import { Container } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { slice } from 'lodash';
import parse from 'html-react-parser';

const Destination = () => {
    const [blogs, setBlogs] = useState([]);
    const [cities, setCities] = useState([]);
    const initialCities = slice(cities, 0, 8)
    const {REACT_APP_API_URL} = process.env
    axios.defaults.baseURL = REACT_APP_API_URL;
    const navigate = useNavigate();

    const getCity = async() => {
        try {
            axios
            .get("pubcity", { headers: {
                "content-type": "application/json"
            }})
            .then((result) => {
                const newCity = result.data.map((cities) => {
                    return {
                      ...cities,
                      type: ''
                    }
                }) 
                setCities(newCity);
            })
            .catch((error) => console.log(error));
        }
        catch (error)
        {
            console.error("Error fetching data:", error);
        }
      }

      const venueCity = (data) => (e) => {
        navigate('/discover', {state: data});
      }

      const listCities = initialCities.map((data) => {
        return (
          
            <SwiperSlide key={'city-'+ data.city_ID}>
                <Card bg='dark'
                      key='dark'
                      text='white' className='text-center' onClick={venueCity(data)} style={{cursor: 'pointer'}}>
                    <Card.Img src={REACT_APP_API_URL + 'uploads/city/' + data.city_img_link} alt={data.city_name} />
                    <Card.ImgOverlay>
                    <Card.Body>
                        <Card.Title className='position-absolute top-50 start-50 translate-middle'><h2 className='destH1'>{data.city_name}</h2></Card.Title>
                        <Card.Text>
                        </Card.Text>
                    </Card.Body>
                    </Card.ImgOverlay>
                </Card>
            </SwiperSlide>
          )

    });

    const cityPlaceholder = [
        <SwiperSlide key={'ph-city-'+1}>
                <Card bg='dark'
                      key='dark'
                      text='white' className='text-center'>
                    <Placeholder animation='glow'>
                        <Placeholder xs={12} style={{ height: '300px'}}/>
                    </Placeholder>
                    <Card.ImgOverlay>
                    <Card.Body>
                        <Card.Title className='position-absolute top-50 start-50 translate-middle'>
                        <Placeholder as={Card.Title} animation='glow'>
                            <Placeholder xs={4}/>
                        </Placeholder>
                        </Card.Title>
                    </Card.Body>
                    </Card.ImgOverlay>
                </Card>
        </SwiperSlide>,
        <SwiperSlide key={'ph-city-'+2}>
                <Card bg='dark'
                    key='dark'
                    text='white' className='text-center'>
                    <Placeholder animation='glow'>
                        <Placeholder xs={12} style={{ height: '300px'}}/>
                    </Placeholder>
                    <Card.ImgOverlay>
                    <Card.Body>
                        <Card.Title className='position-absolute top-50 start-50 translate-middle'>
                        <Placeholder as={Card.Title} animation='glow'>
                            <Placeholder xs={4}/>
                        </Placeholder>
                        </Card.Title>
                    </Card.Body>
                    </Card.ImgOverlay>
                </Card>
        </SwiperSlide>,
        <SwiperSlide key={'ph-city-'+3}>
                <Card bg='dark'
                      key='dark'
                      text='white' className='text-center'>
                    <Placeholder animation='glow'>
                        <Placeholder xs={12} style={{ height: '300px'}}/>
                    </Placeholder>
                    <Card.ImgOverlay>
                    <Card.Body>
                        <Card.Title className='position-absolute top-50 start-50 translate-middle'>
                        <Placeholder as={Card.Title} animation='glow'>
                            <Placeholder xs={4}/>
                        </Placeholder>
                        </Card.Title>
                    </Card.Body>
                    </Card.ImgOverlay>
                </Card>
        </SwiperSlide>,
        <SwiperSlide key={'ph-city-'+4}>
                <Card bg='dark'
                    key='dark'
                    text='white' className='text-center'>
                    <Placeholder animation='glow'>
                        <Placeholder xs={12} style={{ height: '300px'}}/>
                    </Placeholder>
                    <Card.ImgOverlay>
                    <Card.Body>
                        <Card.Title className='position-absolute top-50 start-50 translate-middle'>
                        <Placeholder as={Card.Title} animation='glow'>
                            <Placeholder xs={4}/>
                        </Placeholder>
                        </Card.Title>
                    </Card.Body>
                    </Card.ImgOverlay>
                </Card>
        </SwiperSlide>
      ]

    const getBlog = async() => {
        try {
            axios
            .get("pubblogsLatest", { headers: {
                "content-type": "application/json"
            }})
            .then((result) => {
                const newPost = result.data;
                setBlogs(newPost);
            })
            .catch((error) => console.log(error));
        }
        catch (error)
        {
            console.error("Error fetching data:", error);
        }
      }  

      const blogDetail = (data) => (e) => {
        data.blog_permalink !== null ? navigate('/blog/'+ data.blog_permalink, {state: data}) : navigate('/blogDetail', {state: data});
      }

      const latestBlog = [
        <Col style={{paddingRight: "0px", paddingLeft: "0px"}} key={'blog-'+blogs.blog_ID}>
            <Card bg='dark'
                    
                    text='white'>
                    <Row>
                    <Col lg="4" md="4" xs="12">
                        <Card.Img style={{maxHeight: "250px"}} src={REACT_APP_API_URL + 'uploads/blog/' + blogs.blog_img_link} alt={blogs.blog_ID} />
                    </Col>
                    <Col lg="8" md="8" xs="12">
                        <Card.Body>
                        <Card.Subtitle style={{color: "grey"}}>{blogs.blog_date}</Card.Subtitle>    
                        <Card.Title>{blogs.blog_title}</Card.Title>
                        <Card.Text className='text-white'>
                            {blogs.blog_desc && parse(blogs.blog_desc.substr(0, 400))}
                        </Card.Text>
                        <Row className='my-3 text-center'>
                            <Col><Button className='btn-warning blog-btn' onClick={blogDetail(blogs)}>Selengkapnya</Button></Col>
                        </Row>
                        </Card.Body>        
                    </Col>
                    </Row>  
            </Card>
        </Col>
      ]

      const blogPlaceholder = [
        <Col style={{paddingRight: "0px", paddingLeft: "0px"}} key={'blog-ph-'+1}>
            <Card bg='dark'
                    text='white'>
                    <Row>
                    <Col lg="4" md="4" xs="12">
                    <Placeholder animation='glow'>
                        <Placeholder xs={12} style={{ height: '250px'}}/>
                    </Placeholder>
                    </Col>
                    <Col lg="8" md="8" xs="12">
                        <Card.Body>
                        <Placeholder as={Card.Subtitle} animation='glow' style={{color: "grey"}}>
                            <Placeholder xs={2}/>
                        </Placeholder>    
                        <Placeholder as={Card.Title} animation='glow'>
                            <Placeholder xs={4}/>
                        </Placeholder>
                        <Placeholder as={Card.Text} animation='glow'>
                            <Placeholder xs={12}/>
                            <Placeholder xs={12}/>
                            <Placeholder xs={12}/>
                            <Placeholder xs={6}/>
                        </Placeholder>
                        <Row className='my-3 text-center'>
                            <Col><Placeholder.Button variant="btn-warning" xs={3} /></Col>
                        </Row>
                        </Card.Body>        
                    </Col>
                    </Row>  
            </Card>
        </Col>
      ]

    useEffect(() => {
        getBlog();  
        getCity();
    }, []);
  
      
  return (
    <>
    <Container>
        <Row className='my-2 mx-1'>
            <h2 className='home-h1'>PILIH TUJUAN KOTA ANDA</h2>
            <h3 className='home-h3'>Jelajahi Hal Menyenangkan Untuk Dilakukan di Tempat Tujuanmu</h3>
        </Row>
        <Row className='my-2 mx-1'>
        <Swiper
            slidesPerView={4}
            spaceBetween={20}
            scrollbar={{
                hide: true,
              }}
              modules={[Navigation]}
              navigation={true}
            className="mySwiper"

            breakpoints={{
                0: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },  
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                }
              }}
        >
            {listCities.length > 0 ? listCities : cityPlaceholder}
        </Swiper>
        </Row>  
        <Row className='my-5 px-0 mx-1'>
            <Col style={{paddingRight: "0px", paddingLeft: "0px"}}>
            <Carousel data-bs-theme="dark" controls={false} indicators={false}>
            <Carousel.Item>
                <img
                className="banner_img"
                src={banner}
                alt="First slide"
                />
                <Carousel.Caption>
                <Row>    
                <Col xs='12' md='12'><h1 className='text-white banner-h1'>Are you ready to explore ?</h1></Col>
                <Col xs='6' md='12'><h3 className='text-white banner-h3'>Download Now</h3></Col>
                <Col xs='6' md='12'>
                    <a href='https://play.google.com/store/apps/details?id=com.nb.dev.nightbutterfly' target='_blank' rel="noopener noreferrer">
                        <img className="mid-banner" src={playstore} alt='playstore_image'></img>
                    </a>
                </Col>
                </Row>
                </Carousel.Caption>
            </Carousel.Item>
            
            </Carousel>
            
            </Col>
        </Row>
        <Row className='my-2 mx-1'>
            { latestBlog.length === 1 ? 
                latestBlog : blogPlaceholder 
            } 
        </Row>
    </Container>
    </>
 )
}

export default Destination