import React, {useEffect, useState} from 'react'
import { Container, Row, Col, ListGroup, Card } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import axios from 'axios'

const TermService = () => {
    const [setting, setSetting] = useState([]);
    const {REACT_APP_API_URL} = process.env
    axios.defaults.baseURL = REACT_APP_API_URL;

    useEffect(() => {
      getMetaData()
      window.scrollTo(0, 0);
    }, []);

    const getMetaData = async () => {
      const response = await axios
      .get("settings/8", { headers: {
        "content-type": "application/json"
      }});
      setSetting(response.data);
  }
  return (

    <>
     <Helmet>
          <meta charSet="utf-8" />
          <title>{setting.meta_title}</title>
          <meta name="keywords" content={setting.meta_key} />
          <meta name="description" content={setting.meta_desc} />
          <link rel="canonical" href={window.location.href} />
          <meta name="robots" content="INDEX, FOLLOW"></meta>
          </Helmet>
    <Container>
        <Row className='my-1 text-center justify-content-md-center'>
            <Col>
            <h3>Term of Service</h3>
            </Col>
        </Row>
        <Row className='mb-3 px-2'>
            <Col>
            <Card bg='dark'
                  text='white'>
                    <Card.Body><Card.Text>
                    <Row className='mb-2 text-white'>
                        <Col>These Terms of Service ("Terms") govern your use of Night Butterfly website and associated applications
                            ("Services"). Please read these Terms carefully before accessing or using our Services. </Col>
                    </Row>    
                    <ListGroup as="ol" numbered>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"  
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">Acceptance of Terms</div>
                            By accessing or using our Services, you agree to be bound by these Terms. If you do not agree with any part of the Terms, you may not use our Services.
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">User Account</div>
                            To access certain features of our Services, you may be required to create an account. You are responsible for
                            maintaining the confidentiality of your account information and for all activities that occur under your account.
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"  
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">Use of The Services</div>
                            You agree to use our Services only for their intended purposes and in accordance with these Terms and all applicable
                            laws and regulations.
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">Content</div>
                            Users are responsible for any content they submit through our Services. By submitting content, you grant us a worldwide,
                            non-exclusive, royalty-free license to use, reproduce, modify, adapt, publish, translate, and distribute such content.
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">Privacy</div>
                            Our Privacy Policy, available at [Link to Privacy Policy], explains how we collect, use, and disclose your personal
                            information. By using our Services, you consent to the practices outlined in the Privacy Policy
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">Intellectual Property</div>
                            All content and materials available on our Services, including but not limited to text, graphics, logos, and images, are the
                            property of Night Butterfly and are protected by applicable intellectual property laws.
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">Limitation of Liability</div>
                            We shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in
                            connection with the use of our Services.
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">Termination of Services</div>
                            We reserve the right to terminate or suspend your access to our Services at any time, with or without cause
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">Updates to Terms</div>
                            We may update these Terms from time to time. Any changes will be effective when posted on our Services.
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start text-white"
                        >
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">Governing Law</div>
                            These Terms are governed by and construed in accordance with the laws of UU ITE Tahun 2016. Any disputes arising
                            under or in connection with these Terms shall be resolved in the courts of UU ITE Tahun 2016.
                            </div>
                        </ListGroup.Item>
                        </ListGroup>
                        <Row className='my-2 text-white'>
                            <Col>By using our Services, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you
                                have any questions or concerns, please contact us at adminsupport@nightbutterfly.net</Col>
                        </Row>
                        <Row className='mt-5 text-white'>
                            <Col>
                                <Card.Title>
                                    Night Butterfly <br/>
                                    Rukan Blue Dot Center    
                                </Card.Title>
                                Jl. Gelong Baru Utara No.8, RT.8/RW.3, Tomang, <br/>
                                Kec. Grogol petamburan, Kota Jakarta Barat,<br/>
                                Daerah Khusus Ibukota Jakarta 11440
                            </Col>
                        </Row>
                    </Card.Text></Card.Body>
            </Card>
            </Col>
        </Row>
    </Container>
    </>
  )
}

export default TermService